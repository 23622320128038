const Approval = () => {
  return (
    <div className="d-flex flex-column gap-2">
      <h5>Content Awaiting Approval</h5>
      <div className="d-flex justify-content-center  content-border">
        <span className="fs-18">
          There is no content waiting to be approved
        </span>
      </div>
    </div>
  );
};

export default Approval;
