import { faFile, faFileZipper, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';

const AttachedFiles = ({ file, files, setFiles, i, type }) => {
  const [preview, setPreview] = useState(null);

  const handleRemoveImage = e => {
    e.preventDefault();
    setFiles(files.filter((el, idx) => idx !== i));
  };

  useEffect(() => {
    if (file instanceof File && !file.type.includes('x-zip')) {
      const objectUrl = URL.createObjectURL(file);
      setPreview(objectUrl);

      return () => URL.revokeObjectURL(objectUrl);
    } else setPreview(null);
  }, [file]);

  return (
    <div
      className={`d-flex gap-2 justify-content-between grid-area-${i}`}
      style={{ gridArea: `area-${i}` }}
    >
      <div className="d-flex align-items-center gap-2">
        <div style={{ width: '12px' }}>{i + 1}.</div>
        {type === 'images' || type === 'renders' || type === 'news' ? (
          <>
            {preview ? (
              <img
                src={preview}
                alt={`dropped-image-${i}`}
                width={30}
                height={20}
              />
            ) : (
              <FontAwesomeIcon icon={faFileZipper} />
            )}
          </>
        ) : (
          <>
            {file.type.includes('x-zip') ? (
              <FontAwesomeIcon icon={faFileZipper} />
            ) : (
              <FontAwesomeIcon icon={faFile} />
            )}
          </>
        )}
        <p className="text-sm mb-0 cutted-line">{file.name}</p>
      </div>
      <div onClick={handleRemoveImage} className="hover">
        <FontAwesomeIcon icon={faX} />
      </div>
    </div>
  );
};

export default AttachedFiles;
