import { useMutation } from '@tanstack/react-query';
import https from 'utils/https';
import { ENDPOINTS } from 'utils/endpoints/endpoints';
import { login } from 'redux/slicers/authSlice';
import { useDispatch } from 'react-redux';

async function session() {
  const { data } = await https.get(ENDPOINTS.SESSION);
  return data;
}

export const useSessionMutation = () => {
  const dispatch = useDispatch();
  return useMutation({
    mutationFn: () => session(),
    onSuccess: data => {
      dispatch(login(data));
    }
  });
};
