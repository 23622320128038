import { Form, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';

const FurtitureFixtures = ({ form, action }) => {
  const appsetting = useSelector(state => state.appsetting);
  const { category_tree } = appsetting;
  const findFixturesFurnitures = category_tree.find(el =>
    el.name.toLowerCase().includes('fixtures')
  );
  const findShops = findFixturesFurnitures?.sub_branches?.find(el =>
    el.name.toLowerCase().includes('shops')
  );

  return (
    <Row>
      <fieldset className={'form-legend-item-wrapper mb-4'}>
        <legend className="item-title fs-1 fw-semi-bold">F&F Category:</legend>
        <Form.Control
          as="select"
          required
          className="form-select"
          value={form.category01ISbb_q4sg_content_categoriesID}
          name="category01ISbb_q4sg_content_categoriesID"
          onChange={action}
        >
          <option value="" disabled>
            Choose option
          </option>
          {findShops.sub_branches.map((status, index) => (
            <option value={status._id} key={index} onChange={action}>
              {status.name}
            </option>
          ))}
        </Form.Control>
      </fieldset>
    </Row>
  );
};

export default FurtitureFixtures;
