import React, { useState, useMemo } from 'react';
import PageTitle from 'components/common/PageTitle';
// import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import ProductTabWrapper from 'components/pages/product/ProductTabWrapper';
import createMarkup from 'helpers/createMarkup';
import placeholderImage from 'assets/img/placeholder.png';
import { replaceDashesWithSpaces } from 'helpers/utils';
import { updateElementContentName } from 'redux/slicers/subCategoriesSlice';

const Product = () => {
  // const { t } = useTranslation();

  const { translations } = useSelector(state => state.appsetting);
  const { languageCode } = useSelector(state => state.subCategories);
  const dispatch = useDispatch();
  const {
    category: cat,
    subCategory1: sCat1,
    subCategory2: sCat2,
    subCategory3: sCat3
  } = useParams();
  const category = cat && replaceDashesWithSpaces(cat);
  const subCategory1 = sCat1 && replaceDashesWithSpaces(sCat1);
  const subCategory2 = sCat2 && replaceDashesWithSpaces(sCat2);
  const subCategory3 = sCat3 && replaceDashesWithSpaces(sCat3);
  const title = subCategory3;

  const category_tree = useSelector(state => state.appsetting.category_tree);
  const sub_branches =
    category_tree.find(c => c.name === 'Dashboard').sub_branches || [];
  const inShops =
    sub_branches.find(c => c.name === category).sub_branches || [];
  const shops1 = inShops.find(i => i.name === subCategory1).sub_branches || [];
  const shops2 = shops1.find(s => s.name === subCategory2).sub_branches || [];

  const shops3 = shops2.find(s => s.name === subCategory3).sub_branches || [];
  const description = shops2.find(
    s => s.name === subCategory3
  ).summaryISsmallplaintextbox;
  const [contentId, setContentId] = useState(null);

  const categories = useMemo(() => {
    const result = shops3.map(item => {
      return {
        ...item,
        title: item.name,
        url: `/Dashboard/${category}/${subCategory1}/${subCategory2}/${subCategory3}/${item.name}`,
        icon: item.image01ISfile ?? placeholderImage
      };
    });
    return result;
  }, [shops3]);
  console.log(contentId, 'contentId');
  const translatedTitle = useMemo(() => {
    let result = '';
    if (contentId) {
      result =
        languageCode === 'GB'
          ? title
          : translations?.[contentId]?.[languageCode]?.name;
      dispatch(updateElementContentName(title));
    }

    return result;
  }, [languageCode, contentId, title]);

  return (
    <div>
      <PageTitle title={category === 'Mid Mall' ? title : translatedTitle} />
      {/* <PageTitle title={translatedTitle} /> */}
      {description && (
        <div
          className="text-center pt-2"
          dangerouslySetInnerHTML={createMarkup(description)}
        />
      )}
      <div className="page-content pt-2 mb-6">
        {categories.length > 0 && (
          <ProductTabWrapper
            categories={categories}
            setContentId={setContentId}
          />
        )}
      </div>
    </div>
  );
};

export default Product;
