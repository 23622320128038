import React, { useState } from 'react';
import './footer.css';
import { Col, Row, Nav } from 'react-bootstrap';
import Logo from 'components/common/Logo';
import Flex from 'components/common/Flex';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { bottomMenuRoutes } from 'routes/routes';
import { useTranslation } from 'react-i18next';
import ReactFlagsSelect from 'react-flags-select';
import { getCountryCodes } from 'helpers/utils';
import useMobileScreenSize from 'hooks/useMobileScreenSize';
import { updateLanguage } from 'redux/slicers/subCategoriesSlice';

const Footer = () => {
  const dispatch = useDispatch();
  const isMobile = useMobileScreenSize();

  const inRegions = useSelector(state => state.appsetting.regions) || [];
  const regions = inRegions.filter(r => r.name !== 'ROI');
  const { t, i18n } = useTranslation();
  const defaultLang = i18n.language.toUpperCase();
  const [language, setLanguage] = useState(defaultLang);
  const year = new Date().getFullYear();
  const navigate = useNavigate();

  const onSelect = code => {
    setLanguage(code);
    i18n.changeLanguage(code);
    dispatch(updateLanguage(code));
  };
  const countries = getCountryCodes(regions);
  const onGo = item => {
    if (item.newTab) {
      window.open(item.to, '_blank');
    } else {
      navigate(item.to);
    }
  };

  return (
    <footer className="footer">
      <div className="footer-boder-top"></div>
      <Row className="justify-content-between align-items-center text-center fs--1">
        <Col sm="auto">
          <Flex justifyContent="between" alignItems="center" className="">
            <Logo at="navbar-top" width={40} id="bottomLogo" className="pe-3" />
            <div className="d-flex">
              {isMobile && (
                <ReactFlagsSelect
                  selected={language}
                  onSelect={onSelect}
                  countries={countries}
                  showOptionLabel={false}
                  showSelectedLabel={false}
                  className="bottom-flags p-0"
                />
              )}
              <Nav>
                <Nav.Item as={'li'}>
                  <Nav.Link className="px-2">
                    <div className="">
                      <span>&copy;{`${year} Sky UK`}</span>
                    </div>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as={'li'}>
                  <Nav.Link className="px-2">
                    <div className="">
                      <small>{'v1.44.24'}</small>
                    </div>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
          </Flex>
        </Col>
        <Col sm="auto">
          <div className="footer-navbar">
            <Nav navbar className="footer-nav-items" as="ul">
              {bottomMenuRoutes.children.map((item, index) => (
                <div key={index} className="">
                  <Nav.Item as={'li'} onClick={() => onGo(item)}>
                    <Nav.Link className="footer-nav-link">
                      {t(item.name)}
                    </Nav.Link>
                  </Nav.Item>
                </div>
              ))}
            </Nav>

            {!isMobile && (
              <ReactFlagsSelect
                selected={language}
                onSelect={onSelect}
                countries={countries}
                showOptionLabel={false}
                showSelectedLabel={false}
                className="bottom-flags p-0"
              />
            )}
          </div>
        </Col>
      </Row>
    </footer>
  );
};

export default Footer;
