import { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import DropFiles from '../content/DropFiles';
import Flex from 'components/common/Flex';
import { filesBase64 } from 'utils/files/filesToBase64';
import { useGetContentMutation } from 'utils/rest/content/getContent';
import { useUploadContentMutation } from 'utils/rest/content/uploadContent';

const FurnituresFixturesForm = ({ typeCategory, close, prev }) => {
  const [images, setImages] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [dragging, setDragging] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    category01ISbb_q4sg_content_categoriesID: typeCategory,
    summaryISsmallplaintextbox: '',
    dimensions_net: '',
    materials: ''
  });

  const { mutate: uploadContent, isPending } = useUploadContentMutation();
  const { mutate: getContent } = useGetContentMutation();

  const handleBack = () => {
    prev();
    close();
  };

  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (!isChecked) {
      await setFormData({
        ...formData,
        ...(formData.urlISplaintextbox = null)
      });
    }

    const dataImg = await filesBase64(formData, images, 'img');
    const data = await filesBase64(dataImg, documents, 'doc');
    if (data) {
      await uploadContent(data, {
        onSuccess: () => {
          close();
          getContent();
        }
      });
    }
  };

  const handleDragOver = (e, type) => {
    e.preventDefault();
    if (type === 'img') {
      setDragging({ images: true, documents: false });
    } else {
      setDragging({ images: false, documents: true });
    }
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = (e, type) => {
    e.preventDefault();
    setDragging(false);
    const droppedFiles = Array.from(e.dataTransfer.files);

    if (type === 'img') {
      setImages(prev => [...prev, ...droppedFiles]);
    } else {
      setDocuments(prev => [...prev, ...droppedFiles]);
    }
  };

  useEffect(() => {
    const acceptedImgsFormat = ['image', 'x-zip-compressed'];
    const acceptedDocsFormat = [
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/pdf',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/x-zip-compressed'
    ];

    const checkImagesFile = images.filter(
      el =>
        acceptedImgsFormat.includes(el.type.split('/')[0]) ||
        acceptedImgsFormat.includes(el.type.split('/')[1])
    );

    if (images.length !== checkImagesFile.length) {
      ToastifyNotification({
        message: 'You can drop only "images" or .zip formats  for Images',
        errorMessage: true
      });
      setImages(checkImagesFile);
    } else if (images && images.length != 0 && images?.length > 9) {
      ToastifyNotification({
        message: 'You can drop only 9 images same time',
        errorMessage: true
      });
      const newImages = images.slice(0, 9);
      setImages(newImages);
    } else {
      setImages(images);
    }

    const checkDocsFile = documents.filter(el =>
      acceptedDocsFormat.includes(el.type)
    );

    if (documents.length !== checkDocsFile.length) {
      ToastifyNotification({
        message:
          'You can drop only .pdf, .docx, .xlsx or .zip formats for Documents',
        errorMessage: true
      });
      setDocuments(checkDocsFile);
    } else if (documents && documents.length != 0 && documents?.length > 9) {
      ToastifyNotification({
        message: 'You can drop only 9 documents same time',
        errorMessage: true
      });
      const newDocs = documents.slice(0, 9);
      setDocuments(newDocs);
    } else {
      setDocuments(documents);
    }
  }, [images, documents]);
  return (
    <Form onSubmit={handleSubmit} className="px-6 pt-4">
      <Row>
        <fieldset className={'form-legend-item-wrapper mb-4'}>
          <legend className="item-title fs-1 fw-semi-bold">Name</legend>
          <Form.Control
            value={formData.name}
            name="name"
            onChange={handleChange}
            required
          />
        </fieldset>
      </Row>
      <Row>
        <fieldset className={'form-legend-item-wrapper mb-4'}>
          <legend className="item-title fs-1 fw-semi-bold">Information</legend>
          <Form.Control
            value={formData.summaryISsmallplaintextbox}
            name="summaryISsmallplaintextbox"
            onChange={handleChange}
            required
          />
        </fieldset>
      </Row>
      <div className="d-grid grid-cols-2 row">
        <fieldset className={'form-legend-item-wrapper mb-4'}>
          <legend className="item-title fs-1 fw-semi-bold">Dimensions</legend>
          <Form.Control
            value={formData.dimension_net}
            name="dimensions_net"
            onChange={handleChange}
            required
          />
        </fieldset>

        <fieldset className={'form-legend-item-wrapper mb-4'}>
          <legend className="item-title fs-1 fw-semi-bold">Materials</legend>
          <Form.Control
            value={formData.materials}
            name="materials"
            onChange={handleChange}
            required
          />
        </fieldset>
      </div>

      <Row>
        <fieldset
          className="form-legend-item-wrapper mb-4 position-relative"
          style={{ zIndex: 50 }}
        >
          <legend className="item-title fs-1 d-flex align-items-center">
            <span className="cursor-pointer fw-semi-bold">Images</span>
          </legend>
          <label
            style={{
              minHeight: '120px',
              maxHeight: '120px',
              width: '100%'
            }}
            onDragOver={e => handleDragOver(e, 'img')}
            onDragLeave={() => handleDragLeave('img')}
            onDrop={e => handleDrop(e, 'img')}
          >
            <DropFiles
              dragging={dragging}
              items={images}
              setFiles={setImages}
              form={formData}
              setForm={setFormData}
              type={'images'}
            />
          </label>
        </fieldset>
      </Row>
      <Row>
        <fieldset
          className={'form-legend-item-wrapper mb-4'}
          style={{ position: 'relative' }}
        >
          <legend className="item-title fs-1 d-flex align-items-center">
            <span className="cursor-pointer fw-semi-bold">Documents</span>
          </legend>

          <label
            style={{
              minHeight: '120px',
              maxHeight: '120px',
              width: '100%'
            }}
            onDragOver={e => handleDragOver(e, 'doc')}
            onDragLeave={() => handleDragLeave('doc')}
            onDrop={e => handleDrop(e, 'doc')}
          >
            <DropFiles
              items={documents}
              setFiles={setDocuments}
              type={'documents'}
            />
          </label>
        </fieldset>
      </Row>

      <Row>
        <Flex
          alignItems={'center'}
          className={`gap-2 ${isChecked ? 'mb-2' : 'mb-4'}`}
        >
          <label>Is this to replace an existing content?</label>
          <input
            className="mb-2"
            type="checkbox"
            checked={isChecked}
            onChange={() => setIsChecked(prev => !prev)}
          />
        </Flex>
      </Row>
      {isChecked && (
        <Row>
          <fieldset className={'form-legend-item-wrapper mb-4'}>
            <legend className="item-title fs-1 fw-semi-bold">Link</legend>
            <Form.Control
              placeholder="Please provide the link to the current content"
              value={formData.urlISplaintextbox}
              name="urlISplaintextbox"
              onChange={handleChange}
              required
            />
          </fieldset>
        </Row>
      )}
      <Flex className="gap-5">
        <Button variant="primary" type="submit" disabled={isPending}>
          Submit For Approval
        </Button>
        <Button
          variant="primary"
          type="button"
          onClick={handleBack}
          disabled={isPending}
        >
          Back
        </Button>
      </Flex>
    </Form>
  );
};

export default FurnituresFixturesForm;
