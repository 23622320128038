import { Form } from 'react-bootstrap';
import AttachedFiles from './AttachedFiles';
import NoAttachedFiles from './NoAttachedFiles';
import { useRef } from 'react';

const DropFiles = ({ type, items, setFiles, dragging }) => {
  const inputRef = useRef(null);

  const handleFileSelect = e => {
    const files = Array.from(e.target.files);
    setFiles(prev => [...prev, ...files]);
  };

  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleClear = e => {
    e.preventDefault();
    setFiles([]);
  };

  return (
    <>
      <div
        className="cursor-pointer"
        style={{ width: '100%', height: '120px', transition: 'all 0.4s' }}
        onClick={handleClick}
      >
        {items?.length === 0 ? (
          <NoAttachedFiles dragging={dragging} />
        ) : (
          <>
            <div className="d-grid grid-template-columns-2">
              {items?.map((file, idx) => (
                <AttachedFiles
                  type={type}
                  key={idx}
                  i={idx}
                  file={file}
                  files={items}
                  setFiles={setFiles}
                />
              ))}
              {type !== 'news' && (
                <div className="d-flex justify-content-evenly align-items-center gap-2">
                  {type === 'floor'
                    ? items.length !== 1
                    : items.length !== 9 && (
                        <div>
                          <div className="hover" onClick={handleClick}>
                            Load more
                          </div>
                        </div>
                      )}
                  <div className="d-flex justify-content-evenly align-items-center gap-2">
                    <div className="hover" onClick={handleClear}>
                      Clear all
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
        {items.length !== 9 && (
          <Form.Control
            type="file"
            name={type}
            multiple
            style={{
              display: 'none'
            }}
            onChange={handleFileSelect}
          />
        )}
      </div>
    </>
  );
};

export default DropFiles;
